import request from '../common/request';

// 获取我的预约列表
export const getAppointmentDataList = () => request({
  url: '/api/blade-appointment/appointmentdata/list',
  method: 'get',
});

// 获取预约事项
export const getAppointmentitemList = () => request({
  url: '/api/blade-appointment/appointmentitem/list',
  method: 'get',
});

// 获取预约事项
export const appointmentSubmit = (params) => request({
  url: '/api/blade-appointment/appointmentdata/submit',
  method: 'post',
  data:{
    ...params
  }
});

// 我的预约列表
export const getMyAppointmentList = (params) => request({
  url: '/api/blade-appointment/appointmentdata/myAppointmentList',
  method: 'get',
  params:{
    ...params
  }
});

// 我的预约列表
export const cancelAppointment = (id) => request({
  url: `/api/blade-appointment/appointmentdata/cancelAppointment/${id}`,
  method: 'post',
});