<template>
  <div class="myappoint">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-panel style="margin-bottom:10px" :title="item.itemName" :status="item.appointmentstatusName" v-for="item in list" :key="item.id">
          <van-cell-group :border="false">
            <van-cell :border="false" title="预约时间：" :value="item.appointmentTime" />
            <van-cell :border="false" title="提交时间：" :value="item.createTime" />
            <van-cell
              :border="false"
              title="预约地点："
              value="青原区政务服务中心"
            />
          </van-cell-group>
          <template #footer v-if="item.appointmentstatus === '1'">
            <van-button class="btn" block size="small" type="primary" @click="cancel(item)">取消</van-button>
          </template>
        </van-panel>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Panel, Button,Dialog, Toast } from "vant";
import { getMyAppointmentList,cancelAppointment } from "@/api/appointment";
export default {
  name: "myappoint",
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Panel.name]: Panel,
    [Button.name]: Button,
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      page: {
        current: 0,
        size: 10,
      },
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
     cancel(item){
      Dialog.confirm({
        title: "提示",
        message: "确定取消？",
      })
        .then(async () => {
          const res = await cancelAppointment(item.id);
          // console.log(res);
          Toast(res.msg)
          item.appointmentstatus = 3
          item.appointmentstatusName = '已取消'
        })
        .catch(() => {
          // on cancel
        });
    },
    onRefresh() {
      this.finished = false;
      this.page.current = 0;
      this.list = [];
      this.onLoad();
    },
    onLoad() {
      this.page.current++;
      this.getList();
    },
    async getList() {
      const res = await getMyAppointmentList({
        current: this.page.current,
        size: this.page.size,
      });
      // console.log(res)
      this.refreshing = false;
      this.loading = false;
      if (!res.data.records.length) {
        this.finished = true;
      }
      this.list = this.list.concat(res.data.records);
    },
  },
};
</script>

<style scoped lang="scss">
.myappoint {
  padding: 10px 16px;
}
</style>